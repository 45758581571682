import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../layout"
import ComponentParser from "../ComponentParser"
import Seo from "gatsby-plugin-wpgraphql-seo"

const Page = ({ data }) => {
  const { wpPage: page } = data
  const schemaJSON = JSON.parse(page.seo.schema.raw)
  schemaJSON["@graph"].splice(1, 1)
  page.seo.schema.raw = JSON.stringify(schemaJSON)

  return (
    <Layout
      menuColor={page.whitemenucheckbox.whiteMenu}
      hideNavigation={page.whitemenucheckbox.hideNavigation}
      hideButton={page.whitemenucheckbox.hideButton}
      cleanFooter={page.whitemenucheckbox.cleanFooter}
      whiteBackgroundMenu={page.whitemenucheckbox.whiteBackgroundMenu}
      hideBanner={page.ACFBanner.hideBanner}
    >
      <Seo post={page} />
      <div className="page">
        {page.blocks && (
          <div className="page-content">
            <ComponentParser content={page.blocks} />
          </div>
        )}
      </div>
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      uri
      nodeType
      whitemenucheckbox {
        whiteMenu
        hideNavigation
        hideButton
        cleanFooter
        whiteBackgroundMenu
      }
      ACFBanner {
        hideBanner
      }
      blocks {
        ... on WpAcfFeedbackFormBlock {
          ACFFeedbackForm {
            title
            subtitle
            buttonText
            successTitle
            successLink {
              title
              url
            }
            linkId
          }
        }
        ... on WpAcfTeaserBlock {
          ACFTeaser {
            title
            subtitle
            content
            lottie {
              mediaItemUrl
            }
            landingPageTeaser
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
            button {
              title
              url
            }
            buttonId
          }
        }
        ... on WpAcfGalleryTeaserBlock {
          ACFBlockGalleryTeaser {
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 680)
                }
                extension
                publicURL
              }
            }
          }
        }
        ... on WpAcfFullwidthcardBlock {
          ACFBlockFullWidthCard {
            heading
            subheading
            link {
              title
              url
            }
            linkId
          }
        }
        ... on WpAcfPriceBlock {
          ACFPriceBlock {
            headline
            subheadingUrl {
              title
              url
            }
            subheadingUrlId
            note
            priceObject {
              cardHeadline
              cardPrice
              cardSubheadline
              cents
              cardUrl {
                url
                title
              }
              cardUrlId
            }
          }
        }
        ... on WpAcfWhoWeAreBlock {
          ACFWhoWeAreBlock {
            heading
            content
            subheading
            icon
            advantages {
              advantageHeading
              advantageSubheading {
                title
                url
              }
              icon
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
            imageMobile {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
        ... on WpAcfStartWithMetroBlock {
          ACFStartWithMetro {
            advantages {
              heading
              subheading
            }
            link {
              title
              url
            }
            linkId
            heading
            icon
            paragraph
            subheading
          }
        }
        ... on WpAcfQuotesAndCardsBlock {
          ACFQuotesAndCardsBlock {
            card {
              rowreverse
              heading
              subheading
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  extension
                  publicURL
                }
              }
              link {
                title
                url
              }
              linkId
            }
          }
        }
        ... on WpAcfImageTextBlock {
          ACFImageText {
            heading
            icon
            maskedImage
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
            link {
              title
              url
            }
            linkId
            paragraph
            rowReverse
          }
        }
        ... on WpAcfVideoTextBlock {
          ACFVideoTextBlock {
            video {
              mediaItemUrl
            }
            mobileVideo {
              mediaItemUrl
            }
            subheading
            paragraph
            link {
              title
              url
            }
            linkId
            heading
            imagePreview {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
        ... on WpAcfFaqBlock {
          ACFFAQBlock {
            heading
            link {
              url
              title
            }
            linkId
            questions {
              questionId
            }
          }
        }
        ... on WpAcfAdvantagesBlock {
          ACFAdvantagesBlock {
            advantages {
              heading
              subheading
            }
          }
        }
        ... on WpAcfTitleBlock {
          ACFTitleBlock {
            heading
            centerAlign
            subheading
            text
          }
        }
        ... on WpAcfImageTextScrollBlock {
          ACFImageTextScrollBlock {
            cards {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  extension
                  publicURL
                }
              }
              paragraph
              headline
            }
          }
        }
        ... on WpAcfCardPriceBlock {
          ACFCardPrice {
            link {
              url
              title
            }
            linkId
            heading
            button {
              title
              url
            }
            buttonId
            price
            cents
            note
          }
        }
        ... on WpAcfCalculatorBlock {
          ACFCalculatorBlock {
            heading
            laws
            subheading
            labelAmount
            labelSelect
            labelRate
            labelPayLater
            labelCosts
            inputTooltip
            buttonLabelRates
            buttonLabelLater
            button {
              title
              url
            }
            buttonId
          }
        }
        ... on WpAcfIconsTextBlock {
          ACFIconsText {
            iconsWithText {
              heading
              icon
              subheading
            }
          }
        }
        ... on WpAcfWhereToUseBlock {
          ACFWhereToUse {
            heading
            subheading
            images {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  extension
                  publicURL
                }
              }
            }
          }
        }
        ... on WpAcfImageText2Block {
          ACFImageText2 {
            heading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
            paragraph
            reverseRow
            subheading
            withBackground
          }
        }
        ... on WpAcfTextsWithBackgroundBlock {
          ACFTextsWithBackground {
            cards {
              text
            }
            heading
          }
        }
        ... on WpAcfContactBlock {
          ACFContact {
            contacts {
              arrowIconNextToTheLink
              heading
              icon
              text
              link {
                title
                url
              }
            }
          }
        }
        ... on WpAcfLegelDocsBlock {
          ACFLegalDocs {
            heading
            docs {
              icon
              text
              link {
                title
                url
              }
              linkId
              file {
                id
                mediaItemUrl
              }
            }
          }
        }
        ... on WpAcfDownloadsBlock {
          ACFDownloads {
            files {
              description
              link {
                title
                url
              }
              linkId
            }
            heading
            subheading
          }
        }
        ... on WpAcfInThePressBlock {
          ACFInThePress {
            heading
            cards {
              heading
              link {
                title
                url
              }
              linkId
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  extension
                  publicURL
                }
              }
            }
          }
        }
        ... on WpAcfPressReleasesBlock {
          ACFPressReleases {
            heading
            pressKontakt {
              eMail
              heading
              icon
              text
            }
            releases {
              date
              heading
              link {
                title
                url
              }
              linkId
            }
          }
        }
        ... on WpAcfFlexTeaserBlock {
          ACFFlexTeaser {
            advantages {
              heading
              icon
            }
            hasGreyBackground
            heading
            link {
              title
              url
            }
            linkId
            subheading
            text
          }
        }
        ... on WpAcfPricingBlock {
          ACFPricing {
            note
            cards {
              text
              price
              link {
                title
                url
              }
              linkId
              heading
              cents
            }
            features {
              basic
              featureName
              featureNote
              flex
            }
          }
        }
        ... on WpAcfPartnersBlock {
          ACFPartnersBlock {
            title
            logoTwo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
            logoOne {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
        ... on WpAcfCookiesHeaderBlock {
          ACFCookiesHeaderBlock {
            heading
            link {
              title
              url
            }
            linkId
            subheading
          }
        }
        ... on WpAcfCookieBlock {
          ACFCookieBlock {
            cookies {
              heading
              text
              cookieName
              expandButton
              isTracking
              cookies {
                name
                text
                retention
                serviceName
              }
            }
          }
        }
        ... on WpAcfBenefitsBlock {
          ACFBenefitsBlock {
            benefits {
              heading
              subheading
            }
            heading
            subheading
            text
          }
        }
        ... on WpAcfContactLpBlock {
          ACFContactLP {
            heading
            subheading
            icon
            number {
              title
              url
            }
          }
        }
        ... on WpAcfTestimonialBlock {
          ACFTestimonialLP {
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
            card {
              heading
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  extension
                  publicURL
                }
              }
              subheading
              link {
                title
                url
              }
              linkId
            }
          }
        }
        originalContent
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
